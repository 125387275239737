import React from 'react';
import { Link, navigate } from 'gatsby';

import {
    grid,
    container,
    title,
    box,
    login,
    register,
    guest,
    text,
    button,
    center,
    textLink,
    backLink,
    loginForm,
} from './order-login.module.scss';
import { TOrderSection } from '../../templates/order';
import { orderLoginForm } from '../../formik/login.form';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import useTranslations from '../../hooks/use-translations';
import { useUser } from '../../hooks/use-user';

import FormGenerator from '../molecules/form-generator';
import Button from '../atoms/button';
import Section from '../hoc/section';
import DataHeading from '../molecules/data-heading';
import Title from '../atoms/title';
import GoBackLink from '../atoms/go-back-link';

const GUEST_OPTION_AVAILABILITY = false;

export interface IOrderLoginProps {
    section: TOrderSection;
    TitleTag?: React.ElementType;
}

export default function OrderLogin({ section, TitleTag = 'h2' }: IOrderLoginProps) {
    const t = useTranslations('OrderLogin');
    const {
        sectionId,
        content: { texts },
        settings: { availablePaths },
        css,
        style,
    } = section;
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(userState.login.errors);

    if (userState.isLoggedIn) {
        navigate(availablePaths.order, { replace: true });
    }

    return (
        <Section sectionId={sectionId} classes={{ container: grid }} css={css} style={style}>
            <GoBackLink className={backLink} to={availablePaths.cart}>
                {t.goBack}
            </GoBackLink>
            {texts[0] && (
                <Title className={title} Tag={TitleTag}>
                    {texts[0]}
                </Title>
            )}
            <div className={container}>
                <div className={`${box} ${login}`}>
                    <DataHeading firstColumn={<h2>{t.login.title}</h2>} />
                    <p className={text}>{t.login.content}</p>
                    <FormGenerator
                        formRef={formikRef}
                        className={loginForm}
                        name="OrderLoginForm"
                        formikProps={{ onSubmit: (values) => userState.login.fetch(values) }}
                        fields={orderLoginForm}
                        errorMessage={globalErrorMessage}
                        submitLoading={userState.login.isLoading}
                    />
                    <p className={`${text} ${center}`}>
                        {t.login.disclaimer}{' '}
                        <Link className={textLink} to={availablePaths['password-recovery']}>
                            {t.login.link}
                        </Link>
                    </p>
                </div>
                <div className={`${box} ${register}`}>
                    <DataHeading firstColumn={<h2>{t.register.title}</h2>} />
                    <p className={text}>{t.register.content}</p>
                    <Button
                        className={button}
                        as="link"
                        to={availablePaths.register}
                        state={{ isOrderRegistration: true }}
                    >
                        {t.register.link}
                    </Button>
                </div>
                {GUEST_OPTION_AVAILABILITY && (
                    <div className={`${box} ${guest}`}>
                        <DataHeading firstColumn={<h2>{t.guest.title}</h2>} />
                        <p className={text}>{t.guest.content}</p>
                        <Button className={button} as="link" to={availablePaths.order}>
                            {t.guest.link}
                        </Button>
                    </div>
                )}
            </div>
        </Section>
    );
}
